import { Component, ViewChild } from '@angular/core';

import { Platform, IonContent } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TransferService } from './services/transfer.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  public selectedIndex = 0;
  public empresa = 'Antonio Fayos';
  public appPages = [
    {
      title: 'Inicio',
      url: '/citricos/Inicio',
      icon: 'mail'
    },
    {
      title: 'Curriculum',
      url: '/citricos/Curriculum',
      icon: 'heart'
    },
    {
      title: 'Artículos',
      url: '/citricos/Articulos',
      icon: 'heart'
    },
    {
      title: 'Ayuda',
      url: '/citricos/Ayuda',
      icon: 'archive'
    },
    {
      title: 'Contacte',
      url: '/citricos/Contacte',
      icon: 'paper-plane'
    },
    
  ];
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];

  @ViewChild(IonContent)
  content:IonContent;
  

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private transfer: TransferService,
  ) { 
    console.log("AppComponent");
    this.initializeApp();
    console.log(this.appPages);
    transfer.setSections(this.appPages);
    transfer.setSectionSelected(this.appPages[this.selectedIndex].title);
    console.log("AppComponent | End");
  }

  
  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  

  ngOnInit() {
    console.log("AppComponent | ngOnInit");
    const path = window.location.pathname.split('citricos/')[1];
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }
    console.log("AppComponent | ngOnInit | End");
   
  }

  
}
